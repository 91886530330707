import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const ShoyaTwitter = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Shoya Inc. to Sell Twitter" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Shoya Inc. to Sell Twitter</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. May 24, 2022) </p>
        </div>
        <div className="articleBody">
          <p>
            In late 2019, it was my good fortune to take a road trip from
            Colorado to California with a legendary gentleman by the name of
            Hugo.
          </p>
          <p>
            He’s a master carpenter, a fantastic neon artist, and an outdoor
            adventurer the likes of which I’ve yet to meet again (despite being
            nearly 40 years my elder, he could hike and climb much faster than
            me). He was born and raised in the same town as Che Guevara and
            Lionel Messi. He’s lived and worked on five continents, can converse
            in three languages (that I know of), and last I heard is currently
            leading the renovation of a castle in Spain (where he unwinds by
            doing yoga in a studio that houses original artworks by Dali and
            Picasso).
          </p>
          <p>
            I know sometimes I exaggerate in my writings for comedic effect, so
            I feel the need to clarify to you that Hugo is a real person I know,
            and 100% of what I just described about him is true. I’m convinced
            he was the inspiration for the{" "}
            <a
              href="https://www.youtube.com/watch?v=U18VkI0uDxE"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dos Equis man
            </a>
            .
          </p>
          <p>
            Anyway during this roadtrip, against the backdrop of the mountains
            and deserts of the American West, Hugo told me all kinds of great
            stories about his adventures and some hilarious jokes he picked up
            along the way. He was a real man’s man on the surface, but could
            carry a conversation with sensitivity and tact. This trip had me
            feeling like I was{" "}
            <a
              href="https://www.nationalgeographic.com/travel/article/werner-herzog-interview-on-bruce-chatwin-film-nomad"
              target="_blank"
              rel="noopener noreferer"
            >
              Bruce Chatwin
            </a>{" "}
            , jotting down all the great anecdotes and observations I was
            collecting along the way.
          </p>
          <p>But I made one critical mistake.</p>
          <p>
            Did I document all this with a pen and pad? Or did I use my fucking
            Twitter drafts like a fucking dumbass fucking idiot?
          </p>
          <p>
            My heart sank when I unwittingly updated the app, at which point I
            discovered that Twitter’s iOS app stores drafts on your device and
            wipes them out across updates...
          </p>
          <p className="editorsNote">
            A needle drops on a a vinyl record and crackles -- the sound of my
            immortal Internet gimmick reverberates.
          </p>
          <br />
          <p>
            I was out for revenge, so naturally I called an emergency board
            meeting.
          </p>
          <p>
            ME: We need to buy Twitter. Now. <br />
            BOARD MEMBER #3: But Lil Shoyaright!, we’re already right in the
            middle of acquiring [REDACTED]! <br />
            ME: (sotto voce) I really enjoyed that road trip, man. I gotta get
            those drafts back!!! <br />
            BOARD MEMBER #5: I’m sorry Big Shoya but....what road trip? <br />
            ME: We can sell as soon as I get those drafts, I’m sure Eli Musk
            will wanna buy it, that kid loves my sloppy seconds.
          </p>
          <p>
            Yeah so even though I’ll never get back every little detail that I
            wrote down during that trip, I cherish the memories that stay with
            me. The whole ordeal was a lesson in what those who practice the
            Eastern spiritual traditions call{" "}
            <a href="https://en.wikipedia.org/wiki/Vairagya" target="_blank">
              vairagya
            </a>
            , or detachment. I am in the world but not of the world. Neither are
            you. If we’re lucky, we’ll go places where we just can’t <i>take</i>{" "}
            Twitter drafts, we'll only have space for the light in our eyes.
          </p>
          <p></p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2020-</p>
        </div>
      </div>
    </div>
  )
}
export default ShoyaTwitter
